import React from 'react';
import PropTypes from 'prop-types';
import {
  createPaginationContainer,
  graphql,
} from 'react-relay';
import { Link } from 'found';
import { get } from 'lodash';
import { Helmet } from 'react-helmet';
import { Button, Divider, message } from 'antd';

import { fromGlobalId } from '../../helper';
import { BooleanDropdownFilter, Grid, ImageCellRenderer, WebsiteRefCellRender, currencyFormatter, iDGetter, dateGetter, valueGetter } from '../grid';
import { RecreateIndexMutation, ReindexProductsMutation } from './mutations';
import bulkActions from './bulkActions';
import ParentBundleModal from './ParentBundleModal';
import StockModal from './StockModal';
import { VISIBILITIES } from './ProductForm';

const entityName = 'Product';

const PRODUCT_TYPES = {
  simple: 'Simple',
  bundle: 'Bundle',
  configurable: 'Configurable',
};

export const PRODUCT_STATUSES = {
  enabled: 'Enabled',
  disabled: 'Disabled',
  'end-of-life': 'End-Of-Life',
  pending: 'Pending',
};

export const PRODUCT_VISIBILIIES = VISIBILITIES.reduce((acc, { id, label }) => {
  acc[id] = label;
  return acc;
}, {});

class ProductList extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    viewer: PropTypes.shape({
      brands: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      categories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      products: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      subcategories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      hasMore: PropTypes.func.isRequired,
      isLoading: PropTypes.func.isRequired,
      loadMore: PropTypes.func.isRequired,
      refetchConnection: PropTypes.func.isRequired,
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
    router: PropTypes.shape({
      go: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    children: null,
  }

  constructor(props) {
    super(props);

    const BRANDS = {};
    props.viewer.brands.edges.forEach(edge => {
      BRANDS[edge.node.id] = edge.node.name;
    });

    const SUBCATEGORIES = {};
    props.viewer.subcategories.edges.forEach(edge => {
      SUBCATEGORIES[edge.node.id] = edge.node.name;
    });

    const CATEGORIES = {};
    props.viewer.categories.edges.forEach(edge => {
      CATEGORIES[edge.node.id] = edge.node.name;
    });

    this.state = {
      parentBundleModalVisible: false,
      parentBundleModalProduct: null,
      stockModalVisible: false,
      stockModalProduct: null,
    };

    this.columnDefs = [
      {
        width: 30,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        checkboxSelection: true,
      },
      {
        headerName: 'Action',
        width: 90,
        pinned: 'left',
        sortable: false,
        filter: false,
        suppressMenu: true,
        cellRendererFramework: (params) => {
          if (params.data) {
            const belongsToBundle = get(params, 'data.node.belongsToBundle', false);
            const p = get(params, 'data.node', {});

            return (
              <>
                <Link style={{ userSelect: 'none' }} to={`/product/${fromGlobalId(params.data.node.id).id}`}>Edit</Link>
                {belongsToBundle && (
                  <Button
                    type="link"
                    size="small"
                    style={{ display: 'block', padding: '0px', fontSize: '12px' }}
                    onClick={() => {
                      this.setState({ parentBundleModalVisible: true, parentBundleModalProduct: { id: p.id, name: p.name } });
                    }}
                  >
                    Parent Bundle
                  </Button>
                )}
              </>
            );
          }

          return null;
        },
      },
      {
        headerName: 'ID', valueGetter: iDGetter.bind(this, 'id'), suppressMenu: true, sortable: false, filter: false,
      },
      {
        headerName: 'Image', valueGetter: valueGetter.bind(this, 'mainImage.thumbnail'), suppressMenu: true, sortable: false, filter: false, cellRendererFramework: ImageCellRenderer,
      },
      {
        colId: 'brandId',
        headerName: 'Brand',
        valueGetter: valueGetter.bind(this, 'brandId'),
        filter: 'agNumberColumnFilter',
        valueFormatter: params => BRANDS[params.value],
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: BRANDS, globalId: true, suppressFilterButton: true },
        width: 130
      },
      {
        colId: 'name', headerName: 'Name', valueGetter: valueGetter.bind(this, 'name'), width: 300,
      },
      {
        colId: 'subcategories.id',
        field: 'subcategoryIds',
        headerName: 'Subcategory',
        valueGetter: valueGetter.bind(this, 'subcategoryIds'),
        filter: 'agNumberColumnFilter',
        cellRenderer: params => get(params, 'value', []).map(id => SUBCATEGORIES[id]).join("<br/>"),
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: SUBCATEGORIES, globalId: true, suppressFilterButton: true },
        cellStyle: { 'overflow': 'auto' },
        width: 215
      },
      {
        colId: 'categories.id',
        field: 'categoryIds',
        headerName: 'Category',
        valueGetter: valueGetter.bind(this, 'categoryIds'),
        filter: 'agNumberColumnFilter',
        cellRenderer: params => get(params, 'value', []).map(id => CATEGORIES[id]).join("<br/>"),
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: CATEGORIES, globalId: true, suppressFilterButton: true },
        cellStyle: { 'overflow': 'auto' },
        width: 215
      },
      {
        colId: 'status',
        headerName: 'Status',
        valueGetter: valueGetter.bind(this, 'status'),
        filter: 'agTextColumnFilter',
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: PRODUCT_STATUSES, suppressFilterButton: true },
      },
      {
        colId: 'type',
        headerName: 'Type',
        valueGetter: valueGetter.bind(this, 'type'),
        filter: 'agTextColumnFilter',
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: PRODUCT_TYPES, suppressFilterButton: true },
      },
      {
        colId: 'visibility',
        headerName: 'Visibility',
        valueGetter: valueGetter.bind(this, 'visibility'),
        filter: 'agTextColumnFilter',
        floatingFilterComponent: 'DropdownFilter',
        floatingFilterComponentParams: { dropdownData: PRODUCT_VISIBILIIES, suppressFilterButton: true },
      },
      { colId: 'sku', headerName: 'Sku', valueGetter: valueGetter.bind(this, 'sku') },
      {
        colId: 'model', headerName: 'Model', valueGetter: valueGetter.bind(this, 'model'), width: 150,
      },
      {
        colId: 'secondModel', headerName: 'Second Model', valueGetter: valueGetter.bind(this, 'secondModel'), width: 150,
      },
      {
        colId: 'adenCode', headerName: 'Aden Code', valueGetter: valueGetter.bind(this, 'adenCode'), width: 160,
      },
      {
        colId: 'odooCode', headerName: 'Odoo Code', valueGetter: valueGetter.bind(this, 'odooCode'), width: 160,
      },
      {
        headerName: 'Website Ref', valueGetter: valueGetter.bind(this, 'websiteReference'), suppressMenu: true, sortable: false, filter: false, cellRendererFramework: WebsiteRefCellRender, width: 120,
      },
      {
        colId: 'finalPrice', headerName: 'Final Price', valueGetter: valueGetter.bind(this, 'finalPrice'), valueFormatter: currencyFormatter, suppressMenu: true, sortable: false, filter: false,
      },
      {
        colId: 'costPrice', headerName: 'Cost Price', valueGetter: valueGetter.bind(this, 'costPrice'), valueFormatter: currencyFormatter, suppressMenu: true, sortable: false, filter: false,
      },
      {
        colId: 'price_drop.enable',
        field: 'enable',
        headerName: 'Price Drop',
        valueGetter: valueGetter.bind(this, 'priceDrop.enable'),
        width: 100,
        ...BooleanDropdownFilter
      },
      {
        colId: 'price_drop.startDate',
        field: 'enable',
        filter: 'agDateColumnFilter',
        filterParams: { browserDatePicker: true },
        headerName: 'Price Drop Start',
        valueGetter: dateGetter.bind(this, 'priceDrop.startDate'),
        width: 120
      },
      {
        colId: 'price_drop.endDate',
        field: 'enable',
        filter: 'agDateColumnFilter',
        filterParams: { browserDatePicker: true },
        headerName: 'Price Drop End',
        valueGetter: dateGetter.bind(this, 'priceDrop.endDate'),
        width: 120
      },
      {
        colId: 'online_only',
        headerName: 'Disable Click and Collect',
        valueGetter: valueGetter.bind(this, 'onlineOnly'),
        filter: 'agTextColumnFilter',
        width: 175,
        ...BooleanDropdownFilter,
      },
      {
        colId: 'in_store_only',
        headerName: 'In Store Only',
        valueGetter: valueGetter.bind(this, 'inStoreOnly'),
        filter: 'agTextColumnFilter',
        width: 125,
        ...BooleanDropdownFilter,
      },
      {
        colId: 'stock_available',
        headerName: 'Stock Available',
        valueGetter: valueGetter.bind(this, 'stockAvailable'),
        filter: 'agNumberColumnFilter',
        width: 130,
        cellRendererFramework: (params) => {
          if (params.data) {
            const p = params.data.node;
            const stock = get(p.stocks, 'stock', null);

            return (
              <>
                <div>{p.stockAvailable}</div>
                {stock && (
                  <Button
                    type="link"
                    size="small"
                    style={{ display: 'block', padding: '0px', fontSize: '12px' }}
                    onClick={() => {
                      this.setState({ stockModalVisible: true, stockModalProduct: { name: p.name, stock } });
                    }}
                  >
                    Stock On Hand: {Object.values(stock).reduce((acc, stockCount) => stockCount + acc , 0)}
                  </Button>
                )}
              </>
            );
          }

          return null;
        },
      },
      {
        colId: 'non_stock',
        headerName: 'Non Stock',
        valueGetter: valueGetter.bind(this, 'nonStock'),
        filter: 'agTextColumnFilter',
        width: 130,
        ...BooleanDropdownFilter,
      },
      { colId: 'insertedAt', headerName: 'Created At', valueGetter: dateGetter.bind(this, 'insertedAt'), filter: false },
    ];

    this.datasource = Grid.getDataSource.call(this, 'products');
  }

  reindexProducts = (input = {}) => {
    let mutation = ReindexProductsMutation;

    if (input.replace) {
      mutation = RecreateIndexMutation;
    } else {
      mutation = ReindexProductsMutation;
    }

    mutation.commit({
      environment: this.props.relay.environment,
      viewer: this.props.viewer,
      variables: { input: {} },
      onCompleted: (resp) => {
        if (resp.reindexProducts) {
          message.success(resp.reindexProducts.result);
        }
        if (resp.recreateIndex) {
          message.success(resp.recreateIndex.result);
        }
      },
    });
  }

  render() {
    const { children } = this.props;
    const {
      parentBundleModalVisible,
      parentBundleModalProduct,
      stockModalVisible,
      stockModalProduct,
    } = this.state;

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        {children &&
          <a href="#" onClick={() => { this.props.router.go(-1); }}>Back</a>
        }
        { children }
        <Divider />
        <h1>{entityName}</h1>
        <Link to="/product/new"><Button>Add Product</Button></Link>
        <Button onClick={() => { this.reindexProducts(); }}>Update Index</Button>
        <Button type="primary" danger onClick={() => { this.reindexProducts({ replace: true }); }}>
          Replace Index
        </Button>
        <Grid
          viewer={this.props.viewer}
          columnDefs={this.columnDefs}
          datasource={this.datasource}
          bulkActions={bulkActions}
        />

        <ParentBundleModal
          visible={parentBundleModalVisible}
          product={parentBundleModalProduct}
          dismiss={() => this.setState({parentBundleModalVisible: false, parentBundleModalProduct: null})}
        />
        <StockModal
          visible={stockModalVisible}
          product={stockModalProduct}
          dismiss={() => this.setState({stockModalVisible: false, stockModalProduct: null})}
        />
      </div>
    );
  }
}

export default createPaginationContainer(
  ProductList, {
    viewer: graphql`
    fragment ProductList_viewer on Admin {
      ...AssignProductsToCategories_viewer
      ...AssignProductsToSubcategories_viewer
      ...BulkUpdateProduct_viewer
      ...CopyFromWebsite_viewer
      ...ExportProducts_viewer
      ...ImportProducts_viewer
      ...RemoveCategoriesFromProducts_viewer
      ...RemoveSubcategoriesFromProducts_viewer
      ...UpdatePrices_viewer
      brands(first: 2000, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      categories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      subcategories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
      products(
        first: $count,
        after: $cursor,
        orderBy: $orderBy,
        filterBy: $filterBy,
      ) @connection(key: "ProductList_products") {
        totalCount(filterBy: $filterBy)
        edges {
          node {
            id
            websiteReference {
              id
              country
            }
            name
            type
            brandId
            model
            secondModel
            adenCode
            odooCode
            sku
            type
            finalPrice
            costPrice
            status
            visibility
            insertedAt
            categoryIds
            subcategoryIds
            priceDrop {
              enable
              startDate
              endDate
            }
            mainImage {
              id
              thumbnail
            }
            inStoreOnly
            onlineOnly
            stockAvailable
            nonStock
            belongsToBundle
            stocks
          }
        }
      }
    }
  `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.products;
    },
    getFragmentVariables(prevVars) {
      return {
        ...prevVars,
      };
    },
    getVariables(props, { count, cursor, orderBy }, fragmentVariables) {
      const { filterBy } = fragmentVariables;

      return {
        count,
        cursor,
        orderBy,
        filterBy,
      };
    },
    query: graphql`
    query ProductListPaginationQuery(
      $count: Int!
      $cursor: String
      $orderBy: OrderBy
      $filterBy: [FilterBy]
    ) {
      viewer {
        ...ProductList_viewer
      }
    }
  `,
  },
);
