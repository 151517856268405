import moment from 'moment-timezone';

let DEFAULT_PAGE_SIZE = 100;
const setPageSize = function setPageSize(size = DEFAULT_PAGE_SIZE) {
  localStorage.setItem('pagination_page_size', size);
  DEFAULT_PAGE_SIZE = size;
}
const PAGE_SIZE = function PAGE_SIZE() {
  const storedSize = localStorage.getItem('pagination_page_size');
  if (storedSize) {
    return parseInt(storedSize, 10);
  }
  return DEFAULT_PAGE_SIZE;
}

const loadMore = (props, cb, force = false) => {
  if (!force && (!props.relay.hasMore() || props.relay.isLoading())) {
    return;
  }

  props.relay.loadMore(PAGE_SIZE(), cb);
};

// https://www.ag-grid.com/javascript-grid-infinite-scrolling/
const getDataSource = function getDataSource(connName, defaultFilters = null) {
  return {
    rowCount: this.props.viewer[connName].totalCount, // behave as infinite scroll
    getRows: (params) => {
      const {
        successCallback, startRow, endRow, sortModel, filterModel,
      } = params;

      const cb = () => {
        const data = this.props.viewer[connName].edges;
        const rowsThisPage = data.slice(startRow, endRow);
        successCallback(rowsThisPage, this.props.viewer[connName].totalCount);
      };

      const refetchVariables = { filterBy: defaultFilters };
      if (sortModel && sortModel.length) {
        sortModel.forEach((sort) => {
          refetchVariables.orderBy = {
            field: sort.colId,
            direction: sort.sort,
          };
        });
      }

      if (filterModel && Object.keys(filterModel).length > 0) {
        const filters = [];
        const keys = Object.keys(filterModel);

        for (let i = 0, l = keys.length; i < l; i += 1) {
          const fieldName = keys[i];
          const field = filterModel[fieldName];

          let { filterType, type } = field;
          let filter;

          if (field.filterType === 'date') {
            filterType = 'text';

            switch(type) {
              case "greaterThan":
                filter = `${moment(field.dateFrom).startOf('day').toISOString()}`;
                break;

              case "lessThan":
                filter = `${moment(field.dateFrom).startOf('day').toISOString()}`;
                break;

              case "notEqual":
                filter = `${moment(field.dateFrom).startOf('day').toISOString()}`;
                break;

              default:
                type = 'inRange';
                if (field.dateTo) {
                  filter = `${moment(field.dateFrom).startOf('day').toISOString()},${moment(field.dateTo).endOf('day').toISOString()}`;
                } else {
                  filter = `${moment(field.dateFrom).startOf('day').toISOString()},${moment(field.dateFrom).endOf('day').toISOString()}`;
                }
            }
          } else {
            filter = field.filter.toString();
          }

          filters.push({
            field: fieldName,
            filter,
            filterType,
            type,
          });
        }

        if (Array.isArray(refetchVariables.filterBy)) {
          refetchVariables.filterBy = refetchVariables.filterBy.concat(filters);
        } else {
          refetchVariables.filterBy = filters;
        }
      }

      if (startRow === 0) {
        this.props.relay.refetchConnection(PAGE_SIZE(), cb, refetchVariables, { force: true });
      } else {
        loadMore(this.props, cb);
      }
    },
  };
};

module.exports = {
  getDataSource,
  PAGE_SIZE,
  setPageSize
};
